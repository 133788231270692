import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import * as React from "react"
import { useEffect } from "react"
import Scroll from "react-scroll"
import { useSwipeable } from "react-swipeable"
import Layout from "../components/layout"
import Seo from "../components/seo"
import arrowDownSVG from "../images/arrow-down.svg"
import findImage from "../utils/findImage"
import getImages from "../utils/getImages"

const EditorialTemplate = props => {
  const { data, location } = props

  const [index, setIndex] = React.useState(0)

  const content = data.editorialJson?.content
  const title = data.editorialJson?.title
  const mobileImages = data.mobileImages
  const tabletImages = data.tabletImages
  const desktopImages = data.desktopImages

  const siteTitle = title || data.site.siteMetadata?.title

  var Element = Scroll.Element
  var scroller = Scroll.scroller

  const scroll = element => {
    scroller.scrollTo(element, {
      duration: 700,
      delay: 0,
      smooth: true,
    })
  }

  const config = {
    onSwipedUp: eventData => {
      console.log("onSwipedUp", eventData)
      setIndex(prevIndex =>
        prevIndex + 1 === content?.length ? prevIndex : prevIndex + 1
      )
    },
    onSwipedDown: eventData => {
      console.log("onSwipedDown", eventData)

      setIndex(prevIndex => (prevIndex - 1 < 0 ? 0 : prevIndex - 1))
    },
  }

  const handlers = useSwipeable(config)

  useEffect(() => {
    scroll(`content${index}`)
  }, [index])

  const arrowUp = index > 0 && (
    <div className="content-arrow arrow-top">
      <img
        className="bounce"
        src={arrowDownSVG}
        alt="Arrow up"
        onClick={() => setIndex(prevIndex => prevIndex - 1)}
      />
    </div>
  )

  return (
    <Layout location={location} title={siteTitle} headerMiddleContent={arrowUp}>
      <Seo title={siteTitle} />
      <div {...handlers}>
        {content?.map(
          (
            {
              title,
              text,
              img,
              position,
              text2,
              text3,
              img2,
              img2Type,
              list,
              align,
              justify,
              direction,
            },
            currentIndex
          ) => {
            const images = getImages(
              findImage(mobileImages, img),
              findImage(tabletImages, img),
              findImage(desktopImages, img)
            )

            const image2 = findImage(mobileImages, img2)

            return (
              <Element
                name={`content${currentIndex}`}
                key={`content${currentIndex}`}
              >
                <div
                  className={`editorial ${align ? `flex-${align}` : ""} ${
                    justify ? `justify-${justify}` : ""
                  } ${direction ? `direction-${direction}` : ""}`}
                >
                  <GatsbyImage
                    className="editorial--background"
                    image={images}
                    alt={title || "Background image"}
                  />
                  <div>
                    {title && (
                      <div
                        className={`editorial--label-container editorial--title-container ${
                          position ? `editorial-position-${position}` : ""
                        }`}
                      >
                        <p className="editorial--title">{title}</p>
                      </div>
                    )}
                    {text && (
                      <div
                        className={`editorial--label-container ${
                          position ? `editorial-position-${position}` : ""
                        }`}
                      >
                        <p
                          className="editorial--label"
                          dangerouslySetInnerHTML={{ __html: text }}
                        ></p>
                      </div>
                    )}

                    {text2 && (
                      <div className="editorial--label-container">
                        <p
                          className="editorial--label"
                          dangerouslySetInnerHTML={{ __html: text2 }}
                        ></p>
                      </div>
                    )}

                    {list && (
                      <div className="editorial-list">
                        {list.map((listElement, index) => {
                          const listImage = findImage(
                            mobileImages,
                            listElement.img
                          )

                          return (
                            <div
                              className="editorial-list-element"
                              key={`editorial-list-${index}`}
                            >
                              <GatsbyImage
                                image={listImage}
                                alt={listElement.text}
                              />
                              <span
                                className="editorial-list-label"
                                dangerouslySetInnerHTML={{
                                  __html: listElement.text,
                                }}
                              />
                            </div>
                          )
                        })}
                      </div>
                    )}
                  </div>
                  <div>
                    {img2 && (
                      <GatsbyImage
                        className={`editorial--image ${
                          img2Type === "small" ? "editorial--image-small" : ""
                        }`}
                        image={image2}
                        alt="Image 2"
                      />
                    )}

                    {text3 && (
                      <div className="editorial--label-container">
                        <p
                          className="editorial--label editorial--small"
                          dangerouslySetInnerHTML={{ __html: text3 }}
                        ></p>
                      </div>
                    )}
                  </div>
                </div>
              </Element>
            )
          }
        )}
      </div>
      {index < content?.length - 1 && (
        <div className="content-arrow arrow-down">
          <img
            className="bounce"
            src={arrowDownSVG}
            alt="Arrow down"
            onClick={() => setIndex(prevIndex => prevIndex + 1)}
          />
        </div>
      )}
    </Layout>
  )
}

export default EditorialTemplate

export const pageQuery = graphql`
  query EditorialByKey($key: String!, $language: String!) {
    site {
      siteMetadata {
        title
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    editorialJson(key: { eq: $key }, language: { eq: $language }) {
      title
      content {
        title
        img
        text
        text2
        text3
        img2
        position
        img2Type
        list {
          img
          text
        }
        align
        justify
        direction
      }
    }
    mobileImages: allFile(
      filter: { sourceInstanceName: { eq: "content-images" } }
    ) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
    tabletImages: allFile(
      filter: { sourceInstanceName: { eq: "content-images-tablet" } }
    ) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
    desktopImages: allFile(
      filter: { sourceInstanceName: { eq: "content-images-desktop" } }
    ) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
  }
`
